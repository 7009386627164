html,
body {
  height: 100%;
  background-color: white;
}
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page {
  display: flex;
  flex-direction: row;
  background: white;
  background-image: url("./assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 53rem;
  height: 100%;
  width: 100%;
}

.form-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 5rem;
  font-weight: 550;
  color: #5d5c5b;
  margin: 0;
}

h2 {
  font-size: 3rem;
  font-weight: 550;
  padding-bottom: 2rem;
  color: #5d5c5b;
  margin: 0;
}

span {
  color: #5d5c5b;
  size: 1rem;
}

.input-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem;
}

.faq-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 50rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.about-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.toona-phone {
  filter: drop-shadow(0 0.5rem 0.25rem rgba(0, 0, 0, 0.63));
  width: 100%;
  max-width: 22rem;
}
.toona-phone2 {
  filter: drop-shadow(0 0.5rem 0.25rem rgba(0, 0, 0, 0.63));
  width: 100%;
  max-width: 50rem;
}

.phone-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  margin-top: 1rem;
  width: 20rem;
  border: 1px solid #5d5c5b;
  border-radius: 2rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

span b {
  color: #ff6666;
  font-weight: 400;
}

.submit-btn {
  margin-top: 1rem;
  border: none;
  padding: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #ff6666;
  color: white;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
}

.contact-link {
  text-decoration: underline;
  color: #ff6666;
}

.contact-link:hover {
  color: #f69494;
  text-decoration: underline;
  font-style: italic;
  transition-duration: 0.5s;
}

.discord {
  margin-top: 1rem;
  border: none;
  background-color: white;
}

.submit-btn:hover {
  background-color: #f69494;
  transform: scale(1.1);
  transition-duration: 0.5s;
}

.discord:hover {
  transform: scale(1.2);
  transition-duration: 0.5s;
}
