.about-page {
  /* height: calc(100vh - 6rem); */
  height: 100%;
  width: 100%;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url("../assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
}
.about-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 12rem;
  padding-bottom: 2rem;
}

.about-page b {
  color: #ff6666;
}

.toona-phone-about {
  filter: drop-shadow(0 0.5rem 0.25rem rgba(0, 0, 0, 0.63));
  width: 100%;
  max-width: 50rem;
}

.about {
  max-width: 70rem;
}

.about-title {
  max-width: 60rem;
  font-size: 3rem;
  font-weight: 550;
  color: #5d5c5b;
  margin: 0;
}
