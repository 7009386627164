@media screen and (max-width: 39rem) {
  .page {
    flex-direction: column;
  }
  .about-card {
    margin-top: 5rem;
  }
  .toona-phone {
    width: 80%;
    margin-top: 1rem;
  }
}
