.bakuman {
  position: absolute;
  height: calc(100% - 10rem);
  bottom: 0px;
  right: 50%;
}

@media screen and (max-width: 60rem) {
  .bakuman {
    height: calc(100% - 20rem);
  }
}

.create-page {
  /* height: calc(100vh - 6rem); */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: last baseline;
  justify-content: center;
  background-image: url("../assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
}

.upload-btn {
  border: none;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #ff6666;
  color: white;
  font-weight: bolder;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
}
.upload-btn:hover {
  background-color: #f69494;
  transform: scale(1.1);
  transition-duration: 0.5s;
}

.create-card {
  max-width: 25rem;
  padding: 2rem;
  border-radius: 1rem;
  margin-right: 10rem;
  background-color: #fff;
  box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.button-row {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.link-row {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
}

.guide-link {
  text-decoration: underline;
  font-weight: bolder;
  color: #5d5c5b;
}

@media screen and (max-width: 52rem) {
  .create-page {
    align-items: center;
  }
  .create-card {
    margin: 2rem;
  }
}
