.navbar {
  width: 100%;
  height: 5rem;
  display: grid;
  grid-template-columns: 10rem 1fr 10rem;
  gap: 1rem;
  align-items: center;
  position: fixed;
  border-bottom: rgba(0, 0, 0, 0.16) 1px solid;
  background-color: white;
  overflow: visible;
  z-index: 1000;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.link {
  color: black;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;

  &:hover {
    color: #ff6666;
  }
}

.link-active {
  color: black;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  color: #ff6666;
}
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #ff6666;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #f69494;
}

.logo {
  margin-left: 3rem;
}

.btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
}

.toona {
  font-size: 2rem;
  font-weight: bold;
  color: #5d5c5b;
  display: none;
}

.links-mobile-visible {
  position: absolute;
  top: 5rem;
  right: 0;
  width: 100%;
  background-color: white;
  display: none;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: rgba(0, 0, 0, 0.16) 1px solid;
  border-top: rgba(0, 0, 0, 0.16) 1px solid;
}

.links-mobile-hidden {
  display: none;
}

.join-btn {
  border: none;
  padding: 0.75rem;
  overflow: visible;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #ff6666;
  color: white;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
  height: 3rem;
}

.join-btn:hover {
  background-color: #f69494;
  transform: scale(1.1);
  transition-duration: 0.5s;
}

@media screen and (max-width: 52rem) {
  .navbar {
    grid-template-columns: 1fr;
    width: 100%;
    height: 5rem;
    display: grid;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-bottom: rgba(0, 0, 0, 0.16) 1px solid;
    overflow: visible;
  }
  .links-mobile-visible {
    display: flex;
  }

  .logo {
    margin-top: 0.5rem;
    margin-left: 0rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .toona {
    display: block;
  }

  .links {
    display: none;
  }

  .hamburger {
    display: block;
  }
  .join-btn {
    display: none;
  }
}
