.tool-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 40rem;
}

.app-tools {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  margin-top: 6rem;
  background-color: #fff;
}

span {
  margin: 1rem;
}

.preview-image {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.image-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

@media (max-width: 768px) {
  .image-row {
    flex-direction: column;
    margin-top: 0.5rem;
  }
}

h3 {
  font-size: 2rem;
  font-weight: 550;
  padding-bottom: 2rem;
  color: #5d5c5b;
  margin: 0rem;
  padding: 1rem;
}
